import React from 'react'

import Layout from '../layouts/layout'
import SEO from '../components/seo'

import pupdesktop from '../assets/images/404/404-Error-Page-Pups_03.png'
import pupmobile from '../assets/images/404/404-Error-Page-MB-pup_03.png'
import wordsdesktop from '../assets/images/404/404-Error-Page-04.jpg'
import wordsmobile from '../assets/images/404/404-Error-Page-MB_03.jpg'

import { trackPageData, getPageDetails } from '../analytics'

class NotFoundPage extends React.PureComponent {
  constructor(props) {
    super(props)

    let dataManager = {
      pageDetails: {},
    }

    if (typeof window !== `undefined`) {
      if (window.dataManager) {
        dataManager = window.dataManager
      }
    }
    dataManager.pageDetails = getPageDetails()
  }

  componentDidMount() {
    trackPageData()
  }

  render() {
    return (
      <Layout>
        <div>
          <SEO title="404: Not found" />
          <div class="row mb-4 justify-content-center">
            <div class="col-md-10 col-12">
              <div class="pup-box">
                <img
                  src={pupdesktop}
                  className="img-fluid d-none d-md-inline-block"
                  alt=""
                />
                <img src={pupmobile} className="d-md-none" alt="" />
              </div>
              <div class="stipe-box">
                <div class="content-box">
                  <img
                    src={wordsdesktop}
                    class="main-img d-none d-md-inline-block"
                    alt=""
                  />
                  <img src={wordsmobile} class="img-fluid d-md-none" alt="" />
                  <ul class="option-list">
                    <li>
                      Double check the spelling in the address bar of your
                      browser
                    </li>
                    <li>
                      Use our Search feature to find what you’re looking for
                    </li>
                    <li>Browse &amp; shop our most popular pages below</li>
                  </ul>
                  <ul class="link-list">
                    <li>
                      <a
                        target="_blank"
                        href="https://www.childrensplace.com/us/c/girls-clothing"
                      >
                        SHOP GIRLS<span class="d-none d-md-inline">|</span>
                      </a>
                    </li>
                    <br class="d-md-none" />
                    <li>
                      <a
                        target="_blank"
                        href="https://www.childrensplace.com/us/c/boys-clothing"
                      >
                        SHOP BOYS<span class="d-none d-md-inline">|</span>
                      </a>
                    </li>
                    <br class="d-md-none" />
                    <li>
                      <a
                        target="_blank"
                        href="https://www.childrensplace.com/us/c/toddler-girl-clothes"
                      >
                        SHOP TODDLER GIRLS
                        <span class="d-none d-md-inline">|</span>
                      </a>
                    </li>
                    <br class="d-md-none" />
                    <li>
                      <a
                        target="_blank"
                        href="https://www.childrensplace.com/us/c/toddler-boy-clothes"
                      >
                        SHOP TODDLER BOY
                      </a>
                    </li>
                    <br />
                    <li>
                      <a
                        target="_blank"
                        href="https://www.childrensplace.com/us/c/baby-clothes"
                      >
                        SHOP BABY<span class="d-none d-md-inline">|</span>
                      </a>
                    </li>
                    <br class="d-md-none" />
                    <li>
                      <a
                        target="_blank"
                        href="https://www.childrensplace.com/us/c/childrens-shoes-kids-shoes"
                      >
                        SHOP Shoes<span class="d-none d-md-inline">|</span>
                      </a>
                    </li>
                    <br class="d-md-none" />
                    <li>
                      <a
                        target="_blank"
                        href="https://www.childrensplace.com/us/c/kids-accessories-us"
                      >
                        SHOP ACCESSORIES<span class="hidden-xs">|</span>
                      </a>
                    </li>
                    <br class="d-md-none" />
                    <li>
                      <a
                        target="_blank"
                        href="https://www.childrensplace.com/us/c/clearance-us"
                      >
                        SHOP CLEARANCE
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default NotFoundPage
